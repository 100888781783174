import { Category, Product,User as GraphqlUser, StorePriority, CustomerDefinition, OrderItem, ProductList } from '../../../../generated/graphql';
import { Sku } from "../../../../types";

export interface CartOpen {
  cartOpen: boolean;
}

export const initialCartOpen: CartOpen = {
  cartOpen: false,
};

export interface AccountMenuOpen {
  accountMenuOpen: boolean;
}

export const initialAccountMenuOpen: AccountMenuOpen = {
  accountMenuOpen: false,
};

export interface IsLoggedIn {
  isLoggedIn: boolean;
}

export const initalIsLoggedIn: IsLoggedIn = {
  isLoggedIn: false,
};

if (typeof window !== "undefined") {
  initalIsLoggedIn.isLoggedIn = !!localStorage.getItem("token");
}

export interface CartItems {
  cartItems: Array<{ item: OrderItem }>;
}

export const initalCartItems: CartItems = {
  cartItems: [],
};
export interface Company {
  id: string;
  currency_code: string;
  currency: string;
  vatRate: number;
  paymentMethods: string[];
  discount: number;
  environment: string;
  responsiblePerson: {
    name: string;
    email: string;
    phoneNumber: string;
  };
}

export const InitalCompany: Company = {
  id: "",
  currency_code: "",
  discount: 0,
  environment: "",
  currency: "",
  vatRate: 0,
  paymentMethods: [],
  responsiblePerson: {
    name: "",
    email: "",
    phoneNumber: "",
  },
};

export interface Store {
  id: string;
  external_ref: string;
  name: string;
  line_1: string;
  city: string;
  zipCode: string;
  country: string;
  address: string;
  warehouseId: string;
  termsAndConditionsAccepted: boolean;
  users: GraphqlUser[];
  basePrice: string;
  priority: StorePriority;
  customerDefinition: CustomerDefinition;
  warehouseName: string;
  currency: string;
  salesperson: string;
  status: string;
}


export const InitalStore: Store = {
  id: "",
  external_ref: "",
  name: "",
  line_1: "",
  city: "",
  zipCode: "",
  country: "",
  address: "",
  warehouseId: "",
  termsAndConditionsAccepted: false,
  users: [],
  basePrice: "",
  priority: StorePriority.Medium,
  customerDefinition: CustomerDefinition.Other,
  warehouseName: "",
  currency: "",
  salesperson: "",
  status: "",
};

export interface Notifications {
  notifications: Array<{ message: string; type: string, acknowledged: any, id: string }>;
}

export const InitalNotifications: Notifications = {
  notifications: [],
};

export interface User {
  id: string;
  email: string;
  productLists: ProductList[];
  homePageUrl: string;
  priceListLink: string;
  productsForCampaing: Product[];
  userType: string;
  customerType: string;
  isEnrolledInTeachable: boolean;
  users: {
    id: any;
    email: string;
  }[];
}

export const InitialUser: User = {
  id: "",
  email: "",
  homePageUrl: "",
  priceListLink: "",
  productLists: [],
  productsForCampaing: [],
  userType:"",
  customerType: "",
  isEnrolledInTeachable: false,
  users: []
};

export interface ActiveOrder {
  id: string;
  shippingCost: number;
  totalPrice: number;
  totalVAT: number;
  discount: number;
  vatRateOrder: number;
}

export const InitialActiveOrder: ActiveOrder = {
  id: "",
  shippingCost: 0,
  totalPrice: 0,
  totalVAT: 0,
  discount: 0,
  vatRateOrder: 0,
};

export interface Categories {
  target: string;
  categories: Array<Category>;
  // products: Array<Product>
  loading: boolean;
}

export const InitialCategories: Categories = {
  target: "FOR_DOGS",
  categories: [],
  // products: [],
  loading: true,
};

export interface Modal {
  isOpen: boolean;
  error: string;
  message: string;
  element: React.ComponentType<any> | null;
  props: [];
}

export const InitialModal: Modal = {
  isOpen: false,
  error: "",
  message: "",
  element: null,
  props: [],
};

export interface ApolloRouter {
  prevPath: string;
  currentPath: string;
}

export const InitalApolloRouter: ApolloRouter = {
  prevPath: "",
  currentPath: "",
};

export interface PreOrder {
  selectedWindow: string;
  preOrderWindowName: string;
}

export const InitialPreOrder: PreOrder = {
  selectedWindow: "",
  preOrderWindowName: "",
};


export interface CategoryPage {
  selectedCategory: boolean;
  selectedProduct: boolean;
}

export const InitialCategoryPage: CategoryPage = {
  selectedCategory: false,
  selectedProduct: false,
};