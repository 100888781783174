import { InMemoryCache } from '@apollo/experimental-nextjs-app-support';
import {
  accountMenuOpenVar,
  activeOrderVar,
  apolloRouterVar,
  cartItemsVar,
  cartOpenVar,
  categoriesVar,
  categoryPageVar,
  companyVar,
  isLoggedInVar,
  modalVar,
  notificationsVar,
  preOrderVar,
  storeVar,
  userVar,
} from './appConfig/apolloVars';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cartOpen: {
          read() {
            return cartOpenVar();
          },
        },

        accountMenuOpen: {
          read() {
            return accountMenuOpenVar();
          },
        },

        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },

        cartItems: {
          read() {
            return cartItemsVar();
          },
        },

        company: {
          read() {
            return companyVar();
          },
        },

        store: {
          read() {
            return storeVar();
          },
        },

        notifications: {
          read() {
            return notificationsVar();
          },
        },

        user: {
          read() {
            return userVar();
          },
        },

        activeOrder: {
          read() {
            return activeOrderVar();
          },
        },

        cachedCategories: {
          read() {
            return categoriesVar();
          },
        },
        modal: {
          read() {
            return modalVar();
          },
        },

        router: {
          read() {
            return apolloRouterVar();
          },
        },

        preOrder: {
          read() {
            return preOrderVar();
          },
        },
        categoryPage: {
          read() {
            return categoryPageVar();
          },
        },
      },
    },
  },
});

export default cache;
