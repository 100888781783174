// export const API_ENDPOINT = process.env.NODE_ENV === 'development'
//   ? process.env.NEXT_PUBLIC_GRAPHQL_URI_DEV
//   : process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF !== 'main'
//     ? process.env.NEXT_PUBLIC_GRAPHQL_URI_TEST
//     : process.env.NEXT_PUBLIC_GRAPHQL_URI;

export const getApiEndPoint = () => {
  let endPoint;
  if (process.env.NODE_ENV === "development") {
    endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI_DEV;
  } else {
    switch (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) {
      case "staging":
        endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI_TEST;
        break;
      case "preprod":
        endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI_PREPROD;
        break;
      case "main":
        endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI;
        break;
      case "beta":
        endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI;
        break;
      default:
        endPoint = process.env.NEXT_PUBLIC_GRAPHQL_URI_TEST;
        break;
    }
  }

  return endPoint;
};
