import { makeVar } from "@apollo/client";
import {
  AccountMenuOpen,
  ActiveOrder,
  ApolloRouter,
  CartItems,
  CartOpen,
  Categories,
  CategoryPage,
  Company,
  InitalApolloRouter,
  initalCartItems,
  InitalCompany,
  initalIsLoggedIn,
  InitalNotifications,
  InitalStore,
  initialAccountMenuOpen,
  InitialActiveOrder,
  initialCartOpen,
  InitialCategories,
  InitialCategoryPage,
  InitialModal,
  InitialPreOrder,
  InitialUser,
  IsLoggedIn,
  Modal,
  Notifications,
  PreOrder,
  Store,
  User,
} from "./types";

export const cartOpenVar = makeVar<CartOpen>(initialCartOpen);
export const accountMenuOpenVar = makeVar<AccountMenuOpen>(
  initialAccountMenuOpen
);
export const isLoggedInVar = makeVar<IsLoggedIn>(initalIsLoggedIn);
export const cartItemsVar = makeVar<CartItems>(initalCartItems);
export const companyVar = makeVar<Company>(InitalCompany);
export const storeVar = makeVar<Store>(InitalStore);
export const notificationsVar = makeVar<Notifications>(InitalNotifications);
export const userVar = makeVar<User>(InitialUser);
export const activeOrderVar = makeVar<ActiveOrder>(InitialActiveOrder);
export const categoriesVar = makeVar<Categories>(InitialCategories);
export const modalVar = makeVar<Modal>(InitialModal);
export const apolloRouterVar = makeVar<ApolloRouter>(InitalApolloRouter);
export const preOrderVar = makeVar<PreOrder>(InitialPreOrder);
export const categoryPageVar = makeVar<CategoryPage>(InitialCategoryPage);
