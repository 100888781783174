import { useMemo } from "react";
import merge from "deepmerge";
import { gql, NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cache from "./storage/cache";
import { getApiEndPoint } from "../../config";

import cookie from 'cookie';
import Cookies from 'js-cookie';


interface PageProps {
  props?: Record<string, any>;
}

export const APOLLO_STATE_PROPERTY_NAME = "__APOLLO_STATE__";
export const COOKIES_TOKEN_NAME = "jwt";

let apolloClient: ApolloClient<NormalizedCacheObject> = {} as ApolloClient<NormalizedCacheObject>;

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    cartItems: [ID!]!
    cartOpen: Boolean
  }
`;

const uri = getApiEndPoint();

// const createApolloClient = (ctx?: GetServerSidePropsContext) => {
export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri,
    credentials: "same-origin",
  });

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from cookies
    const token = localStorage.getItem("token");
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(httpLink),
    cache: cache,
  });
};

// export function initializeApollo(initialState = null, ctx = null) {
export function initializeApollo(initialState = null) {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from
    // getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: PageProps) {
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
