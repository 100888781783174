import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
};

export type AddToCartInputDashboard = {
  multipleInput?: InputMaybe<Array<InputMaybe<CartInput>>>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  preOrderWindowId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  skuId?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  companies?: Maybe<Array<Maybe<Company>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  createdOrders?: Maybe<Array<Maybe<OrderCreatedBy>>>;
  editedOrders?: Maybe<Array<Maybe<OrderEditedBy>>>;
  email?: Maybe<Scalars['String']>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  externalRef?: Maybe<Scalars['String']>;
  followedStores?: Maybe<Array<Maybe<Store>>>;
  id?: Maybe<Scalars['String']>;
  lastCall?: Maybe<Array<Maybe<LastCall>>>;
  lastVisit?: Maybe<Array<Maybe<LastVisit>>>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  password?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  resetToken?: Maybe<Scalars['String']>;
  resetTokenExpiry?: Maybe<Scalars['String']>;
  role?: Maybe<AdminRole>;
  salesNotifications?: Maybe<Array<Maybe<SalesNotification>>>;
  selectedStore?: Maybe<Store>;
  soundCelebration?: Maybe<Scalars['Boolean']>;
  states?: Maybe<Array<Maybe<State>>>;
  storeId?: Maybe<Scalars['String']>;
};

export type AdminAuthPayload = {
  __typename?: 'AdminAuthPayload';
  adminToken?: Maybe<Scalars['String']>;
};

export type AdminInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type AdminPage = {
  __typename?: 'AdminPage';
  admin?: Maybe<Admin>;
  listOfAdmins?: Maybe<Array<Maybe<Admin>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  password?: Maybe<Scalars['String']>;
  totalNumberOfOrders?: Maybe<Scalars['Int']>;
};

export enum AdminRole {
  Admin = 'ADMIN',
  SalesPerson = 'SALES_PERSON',
  SuperAdmin = 'SUPER_ADMIN'
}

export type AdminUpdateCompanyInput = {
  adminComment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Banner = {
  __typename?: 'Banner';
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BasePrice = {
  __typename?: 'BasePrice';
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceListLink?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<Price>>>;
  shippings?: Maybe<Array<Maybe<Shipping>>>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export type BoostedProduct = {
  __typename?: 'BoostedProduct';
  boosted?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
};

export type Breed = {
  __typename?: 'Breed';
  external_ref?: Maybe<Scalars['String']>;
  hasBonus?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productsForBreed?: Maybe<Array<Maybe<ProductForBreed>>>;
  showInDropdown?: Maybe<Scalars['Boolean']>;
};

export type BugReportInput = {
  adminEnvironments?: InputMaybe<Scalars['String']>;
  adminName?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  active?: Maybe<Scalars['Boolean']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['date']>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  startDate?: Maybe<Scalars['date']>;
};

export type CartInput = {
  discountPercentage?: InputMaybe<Scalars['Float']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  preOrderWindowId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  skuId?: InputMaybe<Scalars['String']>;
};

export type CartInputPortalMultiple = {
  cartInputs?: InputMaybe<Array<InputMaybe<CartInput>>>;
  orderId?: InputMaybe<Scalars['String']>;
};

export type CategoriesStatic = {
  __typename?: 'CategoriesStatic';
  category?: Maybe<Category>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  productSlug?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  target?: Maybe<CategoryTarget>;
  translations?: Maybe<Array<Maybe<Translate>>>;
};

export enum CategoryTarget {
  ForDogs = 'FOR_DOGS',
  ForHumans = 'FOR_HUMANS',
  Marketing = 'MARKETING'
}

export type Chain = {
  __typename?: 'Chain';
  companies?: Maybe<Array<Maybe<Company>>>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  hex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  adminComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  billingAttention?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingLine1?: Maybe<Scalars['String']>;
  billingLine2?: Maybe<Scalars['String']>;
  billingPhone?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingZipCode?: Maybe<Scalars['String']>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  chain?: Maybe<Chain>;
  chainId?: Maybe<Scalars['String']>;
  corporateID?: Maybe<Scalars['String']>;
  creditLimit?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<CreditScore>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['String']>;
  customerDefinition?: Maybe<CustomerDefinition>;
  customerType?: Maybe<CustomerType>;
  deliveryContactIsSameAsMain?: Maybe<Scalars['Boolean']>;
  deliveryIsSameAsMain?: Maybe<Scalars['Boolean']>;
  discountPercentage?: Maybe<DiscountPercentage>;
  discountPercentageId?: Maybe<Scalars['String']>;
  discountUpdatedAt?: Maybe<Scalars['date']>;
  distributionType?: Maybe<DistributionType>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  external_ref?: Maybe<Scalars['String']>;
  generalAttention?: Maybe<Scalars['String']>;
  generalInfoCity?: Maybe<Scalars['String']>;
  generalInfoCountry?: Maybe<Scalars['String']>;
  generalInfoEmail?: Maybe<Scalars['String']>;
  generalInfoLine1?: Maybe<Scalars['String']>;
  generalInfoLine2?: Maybe<Scalars['String']>;
  generalInfoPhone?: Maybe<Scalars['String']>;
  generalInfoState?: Maybe<Scalars['String']>;
  generalInfoZipCode?: Maybe<Scalars['String']>;
  hasConfirmedInformation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceContactIsSameAsMain?: Maybe<Scalars['Boolean']>;
  invoiceIsSameAsMain?: Maybe<Scalars['Boolean']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  isApplicant?: Maybe<Scalars['Boolean']>;
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizationNumber?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethods>>>;
  priority?: Maybe<CompanyPriority>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  responsiblePerson?: Maybe<Admin>;
  responsiblePersonId?: Maybe<Scalars['String']>;
  shouldSendConfirmationEmails?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EntityStatus>;
  stores?: Maybe<Array<Maybe<Store>>>;
  users?: Maybe<Array<Maybe<User>>>;
  VATNumber?: Maybe<Scalars['String']>;
  vatZone?: Maybe<VatZone>;
  vatZoneId?: Maybe<Scalars['String']>;
};

export enum CompanyPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type Country = {
  __typename?: 'Country';
  admins?: Maybe<Array<Maybe<Admin>>>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export type CountryType = {
  __typename?: 'CountryType';
  country?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
};

export type Course = {
  __typename?: 'Course';
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  teachableId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userCourses?: Maybe<Array<Maybe<UserCourse>>>;
};

export type CreateCampaignInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['date']>;
};

export type CreditNoteInput = {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemInput>>>;
  orderId?: InputMaybe<Scalars['String']>;
  reasonCode?: InputMaybe<Scalars['String']>;
};

export type CreditNoteItemInput = {
  id?: InputMaybe<Scalars['String']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  skuId?: InputMaybe<Scalars['String']>;
  totalQuantityChange?: InputMaybe<Scalars['Float']>;
};

export enum CreditScore {
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type CrossSale = {
  __typename?: 'CrossSale';
  id?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  suggestedProducts?: Maybe<Array<Maybe<Product>>>;
};

export type Currency = {
  __typename?: 'Currency';
  companies?: Maybe<Array<Maybe<Company>>>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<Price>>>;
  shippings?: Maybe<Array<Maybe<Shipping>>>;
  sku?: Maybe<Sku>;
  skuId?: Maybe<Scalars['String']>;
};

export enum CustomerDefinition {
  B2B = 'B2B',
  B2C = 'B2C',
  B2G = 'B2G',
  Importer = 'IMPORTER',
  Other = 'OTHER',
  OwnedByNsd = 'OWNED_BY_NSD'
}

export type CustomersInput = {
  chains?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerDefinition?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
  duplicateUsers?: InputMaybe<Scalars['String']>;
  emptyFields?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  lastCall?: InputMaybe<Scalars['String']>;
  lastContactedType?: InputMaybe<Scalars['String']>;
  lastEducation?: InputMaybe<Scalars['String']>;
  lastOrderSort?: InputMaybe<Scalars['String']>;
  lastVisit?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  perPage?: InputMaybe<Scalars['String']>;
  priorityFilter?: InputMaybe<Scalars['String']>;
  prioritySort?: InputMaybe<Scalars['String']>;
  responsiblePerson?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  selectedTandC?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateSort?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  storeAccess?: InputMaybe<Scalars['String']>;
  totalSales?: InputMaybe<Scalars['String']>;
};

export type CustomersPage = {
  __typename?: 'CustomersPage';
  countries?: Maybe<Array<Maybe<CountryType>>>;
  customers?: Maybe<Array<Maybe<Store>>>;
  orders?: Maybe<Array<Maybe<OrdersType>>>;
  states?: Maybe<Array<Maybe<StateType>>>;
  totalNumberOfCustomers?: Maybe<Scalars['Int']>;
};

export enum CustomerType {
  Ambassador = 'AMBASSADOR',
  Breeders = 'BREEDERS',
  Defense = 'DEFENSE',
  EndCustomer = 'END_CUSTOMER',
  Event = 'EVENT',
  Musher = 'MUSHER',
  Other = 'OTHER',
  PetStore = 'PET_STORE',
  SportStore = 'SPORT_STORE',
  Test = 'TEST',
  Veterinary = 'VETERINARY'
}

export type DiscountInput = {
  company?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  environment?: InputMaybe<Scalars['String']>;
};

export type DiscountPercentage = {
  __typename?: 'DiscountPercentage';
  companies?: Maybe<Array<Maybe<Company>>>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};

export enum DistributionType {
  Hybrid = 'HYBRID',
  Physical = 'PHYSICAL',
  Web = 'WEB'
}

export enum EntityAccess {
  AccessToInternal = 'ACCESS_TO_INTERNAL',
  AccessToPortal = 'ACCESS_TO_PORTAL'
}

export enum EntityStatus {
  Active = 'ACTIVE',
  CreditHold = 'CREDIT_HOLD',
  Inactive = 'INACTIVE',
  OnHold = 'ON_HOLD',
  OneTime = 'ONE_TIME',
  PendingApproval = 'PENDING_APPROVAL'
}

export enum EntityType {
  Category = 'CATEGORY',
  Product = 'PRODUCT'
}

export type Environment = {
  __typename?: 'Environment';
  admins?: Maybe<Array<Maybe<Admin>>>;
  chains?: Maybe<Array<Maybe<Chain>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  defaultWarehouse?: Maybe<Scalars['String']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  number?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  prices?: Maybe<Array<Maybe<Price>>>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  reasonCodes?: Maybe<Array<Maybe<ReasonCode>>>;
  shippings?: Maybe<Array<Maybe<Shipping>>>;
  warehouses?: Maybe<Array<Maybe<Warehouse>>>;
};

export type Event = {
  __typename?: 'Event';
  adminId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Admin>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
};

export type IncorrectCompanyDataInput = {
  attention?: InputMaybe<Scalars['String']>;
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  billingLine1?: InputMaybe<Scalars['String']>;
  billingLine2?: InputMaybe<Scalars['String']>;
  billingPhone?: InputMaybe<Scalars['String']>;
  billingZipCode?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationNumber?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingPhone?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type InventoryPage = {
  __typename?: 'InventoryPage';
  skus?: Maybe<Array<Maybe<Sku>>>;
  totalNumberOfSkus?: Maybe<Scalars['Int']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount?: Maybe<Scalars['Float']>;
  amountInCurrency?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['String']>;
  documentDate?: Maybe<Scalars['date']>;
  documentDueDate?: Maybe<Scalars['date']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  status?: Maybe<InvoiceStatus>;
};

export enum InvoiceStatus {
  Closed = 'Closed',
  Hidden = 'Hidden',
  Open = 'Open'
}

export type Kpi = {
  __typename?: 'KPI';
  list?: Maybe<Array<Maybe<PerMonth>>>;
};

export type LastCall = {
  __typename?: 'LastCall';
  adminId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Admin>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
};

export type LastVisit = {
  __typename?: 'LastVisit';
  adminId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Admin>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
};

export type Me = {
  __typename?: 'Me';
  categories?: Maybe<Array<Maybe<Category>>>;
  homePageUrl?: Maybe<Scalars['String']>;
  isEnrolledInTeachable?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  order?: Maybe<Order>;
  priceListLink?: Maybe<Scalars['String']>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  productsForCampaing?: Maybe<Array<Maybe<Product>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type MeAdmin = {
  __typename?: 'MeAdmin';
  admin?: Maybe<Admin>;
  basePrices?: Maybe<Array<Maybe<BasePrice>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  currency?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  shippingFee?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  warehouses?: Maybe<Array<Maybe<Warehouse>>>;
};

export type Measure = {
  __typename?: 'Measure';
  id?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  measureSizes?: Maybe<Array<Maybe<MeasureSize>>>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
};

export type MeasureSize = {
  __typename?: 'MeasureSize';
  id?: Maybe<Scalars['String']>;
  measure?: Maybe<Measure>;
  measureId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type MeasureSizeInput = {
  size?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type Message = {
  __typename?: 'Message';
  message?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions?: Maybe<TermsAndConditions>;
  acknowledgeNotification?: Maybe<Array<Maybe<Notification>>>;
  acknowledgeNotificationAdmin?: Maybe<Message>;
  addAdminToPermission?: Maybe<Permission>;
  addColorsToProducts?: Maybe<Message>;
  addCompanyToCampaing?: Maybe<Company>;
  addCountryToAdmin?: Maybe<Admin>;
  addInventoryToStocks?: Maybe<Array<Maybe<Stock>>>;
  addLastContacted?: Maybe<LastVisit>;
  addMultipleAdminsToPermission?: Maybe<Permission>;
  addMultiplePermissionsToAdmin?: Maybe<Admin>;
  addNOEnvironmentToAllBasePrices?: Maybe<Message>;
  addOutOfStockTextToSku?: Maybe<Sku>;
  addPermissionToAdmin?: Maybe<Admin>;
  addProductListsToProduct?: Maybe<Message>;
  addProductToCampaing?: Maybe<Product>;
  addQuestion?: Maybe<TrainingQuestion>;
  addSelectedStoreToAdminsMissingIt?: Maybe<Message>;
  addSlugsToProduct?: Maybe<Message>;
  addStateToAdmin?: Maybe<Admin>;
  addStockToBackInStockModel?: Maybe<Message>;
  addToCart?: Maybe<Order>;
  addToCartDashboard?: Maybe<Order>;
  addToCartPortalMultiple?: Maybe<Order>;
  adminCancelOrder?: Maybe<Order>;
  adminEditOrder?: Maybe<Order>;
  adminRequestReset?: Maybe<Message>;
  adminResetPassword?: Maybe<Message>;
  adminSignIn?: Maybe<AdminAuthPayload>;
  approveCustomer?: Maybe<Message>;
  boostProduct?: Maybe<Product>;
  boostProductForStore?: Maybe<Store>;
  changeBillingCountryName?: Maybe<Message>;
  changeCreatedBy?: Maybe<Message>;
  changeCustomerOrderId?: Maybe<Order>;
  changeOrderComment?: Maybe<Order>;
  changeOrderDiscount?: Maybe<Order>;
  changeOrderItemDiscount?: Maybe<Order>;
  changeOrderItemPrice?: Maybe<Order>;
  changeOrderItemReasonCode?: Maybe<Order>;
  changeOrderItemShipmentType?: Maybe<Order>;
  changeOrderReasonCode?: Maybe<Order>;
  changeOrderShipmentDate?: Maybe<Order>;
  changeOrderStatus?: Maybe<Order>;
  changeOrderType?: Maybe<Order>;
  changeOrderWarehouse?: Maybe<Order>;
  changePriceLink?: Maybe<Message>;
  changeShowInDropdown?: Maybe<Breed>;
  changeStatus?: Maybe<TrainingQuestion>;
  changeStorePriority?: Maybe<Store>;
  changeUserStore?: Maybe<User>;
  checkOrderInventory?: Maybe<PlaceOrder>;
  checkout?: Maybe<Order>;
  checkVatNumber?: Maybe<Scalars['Boolean']>;
  confirmInformation?: Maybe<Message>;
  copyBillingAddressToGeneralInfo?: Maybe<Message>;
  copyVismaSkuAssortmentToAnotherVisma?: Maybe<Message>;
  createAdmin?: Maybe<Admin>;
  createColors?: Maybe<Message>;
  createCreditNote?: Maybe<Order>;
  createOrUpdateBanner?: Maybe<Banner>;
  createOrUpdateCampaign?: Maybe<Campaign>;
  createOrUpdateInvoices?: Maybe<Message>;
  createPermission?: Maybe<Permission>;
  createSalesOrder?: Maybe<Order>;
  createShippingsForEnvironment?: Maybe<Message>;
  createStocksForWarehouse?: Maybe<Message>;
  createStoreLocatorSheet?: Maybe<Message>;
  createStripeInvoice?: Maybe<Message>;
  createStripeProducts?: Maybe<Message>;
  createStripeUserByEmail?: Maybe<Message>;
  createStripeUsers?: Maybe<Message>;
  createTaxJarUsers?: Maybe<Message>;
  createTeachableUsers?: Maybe<Message>;
  createTeachableUsersByCountry?: Maybe<Message>;
  createVatZones?: Maybe<Message>;
  customerRegistration?: Maybe<Company>;
  deactivateSingleStoreToSalesLayer?: Maybe<Message>;
  deactivateUScustomers?: Maybe<Message>;
  deleteBanner?: Maybe<Message>;
  deleteCampaing?: Maybe<Campaign>;
  deleteCompanyFromCampaing?: Maybe<Company>;
  deleteDuplicateStocks?: Maybe<Message>;
  deleteNotification?: Maybe<Notification>;
  deleteOrder?: Maybe<Message>;
  deletePermission?: Maybe<Permission>;
  deletePointsOfSale?: Maybe<Array<Maybe<Message>>>;
  deleteProductFromCampaing?: Maybe<Product>;
  deleteShipping?: Maybe<Message>;
  editOrder?: Maybe<Order>;
  enrollSelectedCustomersToCourse?: Maybe<Message>;
  enrollStudentsToCourse?: Maybe<Message>;
  enrollToTeachableCourse?: Maybe<Message>;
  findDuplicateProducts?: Maybe<Message>;
  findOrdersByStatusAndAlertAdmins?: Maybe<Message>;
  followStore?: Maybe<Store>;
  getAlertsForProduct?: Maybe<ProductBackInStock>;
  getAlertsForSku?: Maybe<SkuBackInStock>;
  getCompaniesCsv?: Maybe<Message>;
  getListOfAffectedUsers?: Maybe<Message>;
  getPIdata?: Maybe<Message>;
  importMissingStoresToSalesLayer?: Maybe<Message>;
  importStoresFromDatabaseToSalesLayer?: Maybe<Message>;
  importStoresToSalesLayer?: Maybe<Message>;
  informAdminsAboutIncorrectData?: Maybe<Message>;
  inviteAdmin?: Maybe<Message>;
  inviteUser?: Maybe<Message>;
  loginViaTeachable?: Maybe<AuthPayload>;
  markAllSalesNotificationAsRead?: Maybe<Message>;
  markSalesNotificationAsRead?: Maybe<Message>;
  migrateDataForAllStores?: Maybe<Message>;
  migrateSkus?: Maybe<Message>;
  migrateStoreData?: Maybe<Message>;
  placeOrder?: Maybe<PlaceOrder>;
  populateQuestions?: Maybe<Message>;
  recalculatePrices?: Maybe<Message>;
  registerCustomersOnTeachableByAssortment?: Maybe<Message>;
  registerSelectedCustomersOnTeachable?: Maybe<Message>;
  removeCountryFromAdmin?: Maybe<Admin>;
  removeCustomerNotFoundInVisma?: Maybe<Message>;
  removeFromCart?: Maybe<Order>;
  removeLastContacted?: Maybe<LastVisit>;
  removeOutOfStockItems?: Maybe<PlaceOrder>;
  removePreOrderProduct?: Maybe<PreOrderProduct>;
  removeSlugs?: Maybe<Message>;
  removeStateFromAdmin?: Maybe<Admin>;
  reportABug?: Maybe<Message>;
  requestReset?: Maybe<Message>;
  resendEmail?: Maybe<Message>;
  resetPassword?: Maybe<Message>;
  salesLayerCategoryPopulate?: Maybe<Message>;
  salesLayerProductPopulate?: Maybe<Message>;
  salesNotification?: Maybe<Message>;
  seedBoostedProducts?: Maybe<Message>;
  seedChains?: Maybe<Message>;
  seedCountries?: Maybe<Message>;
  seedCountriesToOrders?: Maybe<Message>;
  seedCountriesToStores?: Maybe<Message>;
  seedNewSkus?: Maybe<Message>;
  seedPriceLinks?: Maybe<Message>;
  seedSkus?: Maybe<Message>;
  seedStates?: Maybe<Message>;
  seedStatesChina?: Maybe<Message>;
  selectStore?: Maybe<MeAdmin>;
  sendEmailToManagers?: Maybe<Message>;
  sendOrderToStripe?: Maybe<Message>;
  signIn?: Maybe<AuthPayload>;
  singleStoreToSalesLayer?: Maybe<Message>;
  syncProductListsOfSkusToProduct?: Maybe<Message>;
  translateEntity?: Maybe<Translate>;
  trimZipcodes?: Maybe<Message>;
  updateAccessForStore?: Maybe<Message>;
  updateAdminDetails?: Maybe<Admin>;
  updateApplicantData?: Maybe<Message>;
  updateAssortmentOfCustomer?: Maybe<Message>;
  updateBasePriceOfStore?: Maybe<Message>;
  updateCategoryRank?: Maybe<Category>;
  updateChainOfCompany?: Maybe<Message>;
  updateCompany?: Maybe<Company>;
  updateCompanyInformationFromVisma?: Maybe<Message>;
  updateCoursesFromTeachable?: Maybe<Message>;
  updateCurrencyOfCompany?: Maybe<Message>;
  updateCustomerDefinition?: Maybe<Message>;
  updateCustomerDefinitionOfCompany?: Maybe<Message>;
  updateCustomerDistribution?: Maybe<Message>;
  updateCustomerInformationFromVisma?: Maybe<Message>;
  updateCustomers?: Maybe<Message>;
  updateCustomerType?: Maybe<Message>;
  updateDataOfCustomer?: Maybe<Store>;
  updateDiscountLock?: Maybe<OrderItem>;
  updateDiscounts?: Maybe<Message>;
  updateDuplicateUsers?: Maybe<Store>;
  updateEnvironmentOfAdmin?: Maybe<Message>;
  updateEnvironmentOfCompany?: Maybe<Message>;
  updateHoldOrdersForStore?: Maybe<Message>;
  updateIncomingSkuInventory?: Maybe<Message>;
  updateMaintenanceMode?: Maybe<Settings>;
  updateNotification?: Maybe<Notification>;
  updateOrCreateShipping?: Maybe<Message>;
  updateOrders?: Maybe<Message>;
  updateOutOfStockTextByColor?: Maybe<Product>;
  updatePaymentMethodsOfCompany?: Maybe<Message>;
  updatePreOrderWindow?: Maybe<PreOrderWindow>;
  updatePrices?: Maybe<Message>;
  updateProductDetails?: Maybe<Product>;
  updateProductListOfAdmin?: Maybe<Message>;
  updateProductListOfCompany?: Maybe<Message>;
  updateProductListsOfCourse?: Maybe<Message>;
  updateProductRank?: Maybe<Product>;
  updateProducts?: Maybe<Message>;
  updateQuestion?: Maybe<TrainingQuestion>;
  updateSalesPersonForCompany?: Maybe<Message>;
  updateShippingForCompany?: Maybe<Message>;
  updateShouldSendEmailConfrimation?: Maybe<Message>;
  updateSkuAccess?: Maybe<Sku>;
  updateSkuAndProductColors?: Maybe<Message>;
  updateSkuColorRank?: Maybe<Message>;
  updateSkus?: Maybe<Message>;
  updateSkusInventory?: Maybe<Message>;
  updateSkusProductLists?: Maybe<Message>;
  updateStatusOfCustomer?: Maybe<Store>;
  updateStockText?: Maybe<Message>;
  updateStoreWebsiteData?: Maybe<Store>;
  updateVatInformationForCompany?: Maybe<Message>;
  updateVatNumber?: Maybe<Message>;
  updateWarehouseRank?: Maybe<Warehouse>;
};


export type MutationAcceptTermsAndConditionsArgs = {
  storeId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationAcknowledgeNotificationArgs = {
  notificationId?: InputMaybe<Scalars['String']>;
};


export type MutationAcknowledgeNotificationAdminArgs = {
  notificationId?: InputMaybe<Scalars['String']>;
};


export type MutationAddAdminToPermissionArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  permissionId?: InputMaybe<Scalars['String']>;
};


export type MutationAddCompanyToCampaingArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
};


export type MutationAddCountryToAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
};


export type MutationAddLastContactedArgs = {
  comment?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  mutation?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationAddMultipleAdminsToPermissionArgs = {
  adminIds?: InputMaybe<Scalars['String']>;
  permissionId?: InputMaybe<Scalars['String']>;
};


export type MutationAddMultiplePermissionsToAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  permissionIds?: InputMaybe<Scalars['String']>;
};


export type MutationAddOutOfStockTextToSkuArgs = {
  outOfStockText?: InputMaybe<Scalars['String']>;
  skuId?: InputMaybe<Scalars['String']>;
};


export type MutationAddPermissionToAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  permissionId?: InputMaybe<Scalars['String']>;
};


export type MutationAddProductToCampaingArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type MutationAddQuestionArgs = {
  answer?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};


export type MutationAddStateToAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
};


export type MutationAddToCartArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  cartInput?: InputMaybe<CartInput>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationAddToCartDashboardArgs = {
  addToCartInputDashboard?: InputMaybe<AddToCartInputDashboard>;
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationAddToCartPortalMultipleArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  cartInputPortalMultiple?: InputMaybe<CartInputPortalMultiple>;
  currency?: InputMaybe<Scalars['String']>;
};


export type MutationAdminCancelOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationAdminEditOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationAdminRequestResetArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type MutationAdminResetPasswordArgs = {
  confirmPassword?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  resetToken?: InputMaybe<Scalars['String']>;
};


export type MutationAdminSignInArgs = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationApproveCustomerArgs = {
  approved?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['String']>;
};


export type MutationBoostProductArgs = {
  environmentId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type MutationBoostProductForStoreArgs = {
  productId?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationChangeCreatedByArgs = {
  adminEmail?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationChangeCustomerOrderIdArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  storeOrderId?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderCommentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderDiscountArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderItemDiscountArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  environment?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderItemPriceArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
};


export type MutationChangeOrderItemReasonCodeArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderItemShipmentTypeArgs = {
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  shipmentType?: InputMaybe<OrderShipmentType>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderReasonCodeArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderShipmentDateArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  scheduledShipmentDate?: InputMaybe<Scalars['String']>;
};


export type MutationChangeOrderStatusArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
};


export type MutationChangeOrderTypeArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderOriginType?: InputMaybe<OrderOriginType>;
  orderShipmentType?: InputMaybe<OrderShipmentType>;
};


export type MutationChangeOrderWarehouseArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['String']>;
};


export type MutationChangePriceLinkArgs = {
  id?: InputMaybe<Scalars['String']>;
  priceListLink?: InputMaybe<Scalars['String']>;
};


export type MutationChangeShowInDropdownArgs = {
  id?: InputMaybe<Scalars['String']>;
  showInDropdown?: InputMaybe<Scalars['String']>;
};


export type MutationChangeStatusArgs = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TrainingQuestionStatus>;
};


export type MutationChangeStorePriorityArgs = {
  priority?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationChangeUserStoreArgs = {
  storeId?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationCheckOrderInventoryArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  remove?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCheckoutArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationCheckVatNumberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  VATNumber?: InputMaybe<Scalars['String']>;
};


export type MutationCopyVismaSkuAssortmentToAnotherVismaArgs = {
  fromEnv?: InputMaybe<Scalars['String']>;
  toEnv?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAdminArgs = {
  admin?: InputMaybe<AdminInput>;
};


export type MutationCreateCreditNoteArgs = {
  input?: InputMaybe<CreditNoteInput>;
};


export type MutationCreateOrUpdateBannerArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  countryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  linkText?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOrUpdateCampaignArgs = {
  input?: InputMaybe<CreateCampaignInput>;
};


export type MutationCreateOrUpdateInvoicesArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePermissionArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSalesOrderArgs = {
  orderType?: InputMaybe<OrderOriginType>;
  shipmentType?: InputMaybe<OrderShipmentType>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateShippingsForEnvironmentArgs = {
  environmentExternalRef?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStocksForWarehouseArgs = {
  env?: InputMaybe<Scalars['String']>;
  warehouseName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStoreLocatorSheetArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStripeInvoiceArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStripeUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStripeUsersArgs = {
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
};


export type MutationCreateTaxJarUsersArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
};


export type MutationCreateTeachableUsersArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  teachableUsers?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTeachableUsersByCountryArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type MutationCreateVatZonesArgs = {
  env?: InputMaybe<Scalars['String']>;
};


export type MutationCustomerRegistrationArgs = {
  input?: InputMaybe<RegisterCustomerInput>;
};


export type MutationDeactivateSingleStoreToSalesLayerArgs = {
  external_ref?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBannerArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteCampaingArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteCompanyFromCampaingArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteNotificationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePermissionArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePointsOfSaleArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProductFromCampaingArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteShippingArgs = {
  shippingId?: InputMaybe<Scalars['String']>;
};


export type MutationEditOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationEnrollSelectedCustomersToCourseArgs = {
  courseId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<SelectedCustomers>;
};


export type MutationEnrollStudentsToCourseArgs = {
  courseId?: InputMaybe<Scalars['String']>;
  productListIds?: InputMaybe<Scalars['String']>;
};


export type MutationEnrollToTeachableCourseArgs = {
  courseId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationFindOrdersByStatusAndAlertAdminsArgs = {
  orderStatus?: InputMaybe<Scalars['String']>;
};


export type MutationFollowStoreArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationGetAlertsForProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type MutationGetAlertsForSkuArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  skuId?: InputMaybe<Scalars['String']>;
};


export type MutationGetCompaniesCsvArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationGetPIdataArgs = {
  paymentIntentId?: InputMaybe<Scalars['String']>;
};


export type MutationImportMissingStoresToSalesLayerArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type MutationImportStoresFromDatabaseToSalesLayerArgs = {
  country?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};


export type MutationImportStoresToSalesLayerArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type MutationInformAdminsAboutIncorrectDataArgs = {
  incorrectCompanyDataInput?: InputMaybe<IncorrectCompanyDataInput>;
};


export type MutationInviteAdminArgs = {
  email?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};


export type MutationInviteUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
};


export type MutationLoginViaTeachableArgs = {
  teachableToken?: InputMaybe<Scalars['String']>;
};


export type MutationMarkSalesNotificationAsReadArgs = {
  id?: InputMaybe<Scalars['String']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMigrateStoreDataArgs = {
  existingStoreId?: InputMaybe<Scalars['String']>;
  newStoreId?: InputMaybe<Scalars['String']>;
};


export type MutationPlaceOrderArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createStripePaymentSku?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
  onHold?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['String']>;
  scheduledShipmentDate?: InputMaybe<Scalars['String']>;
  storeOrderId?: InputMaybe<Scalars['String']>;
};


export type MutationRecalculatePricesArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterCustomersOnTeachableByAssortmentArgs = {
  productLists?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterSelectedCustomersOnTeachableArgs = {
  input?: InputMaybe<SelectedCustomers>;
};


export type MutationRemoveCountryFromAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveCustomerNotFoundInVismaArgs = {
  env?: InputMaybe<Scalars['String']>;
  external_ref?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveFromCartArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveLastContactedArgs = {
  id?: InputMaybe<Scalars['String']>;
  mutation?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveOutOfStockItemsArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationRemovePreOrderProductArgs = {
  preOrderProductId?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveStateFromAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
};


export type MutationReportABugArgs = {
  input?: InputMaybe<BugReportInput>;
};


export type MutationRequestResetArgs = {
  email?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResendEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResetPasswordArgs = {
  confirmPassword?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  resetToken?: InputMaybe<Scalars['String']>;
};


export type MutationSalesNotificationArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationSelectStoreArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationSendEmailToManagersArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationSendOrderToStripeArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type MutationSignInArgs = {
  email?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationSingleStoreToSalesLayerArgs = {
  external_ref?: InputMaybe<Scalars['String']>;
};


export type MutationTranslateEntityArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  field?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  translation?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAccessForStoreArgs = {
  access?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdminDetailsArgs = {
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  soundCelebration?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateApplicantDataArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  corporateID?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAssortmentOfCustomerArgs = {
  assortmentId?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBasePriceOfStoreArgs = {
  basePriceId?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCategoryRankArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateChainOfCompanyArgs = {
  chainId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCompanyArgs = {
  input?: InputMaybe<AdminUpdateCompanyInput>;
};


export type MutationUpdateCompanyInformationFromVismaArgs = {
  env?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCurrencyOfCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerDefinitionArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  customerDefinition?: InputMaybe<CustomerDefinition>;
};


export type MutationUpdateCustomerDefinitionOfCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  customerDefinition?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerDistributionArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  customerDistribution?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerInformationFromVismaArgs = {
  env?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomersArgs = {
  updateCustomersInput?: InputMaybe<UpdateCustomersInput>;
};


export type MutationUpdateCustomerTypeArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<CustomerType>;
};


export type MutationUpdateDataOfCustomerArgs = {
  input?: InputMaybe<UpdateDataCustomerInput>;
};


export type MutationUpdateDiscountLockArgs = {
  discountLock?: InputMaybe<Scalars['Boolean']>;
  orderItemId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDiscountsArgs = {
  updateDiscountsInput?: InputMaybe<UpdateDiscountsInput>;
};


export type MutationUpdateDuplicateUsersArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEnvironmentOfAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEnvironmentOfCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHoldOrdersForStoreArgs = {
  holdOrders?: InputMaybe<Scalars['Boolean']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateIncomingSkuInventoryArgs = {
  env?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMaintenanceModeArgs = {
  maintenanceMode?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateNotificationArgs = {
  input?: InputMaybe<NotificationsInput>;
};


export type MutationUpdateOrCreateShippingArgs = {
  input?: InputMaybe<ShippingInput>;
};


export type MutationUpdateOrdersArgs = {
  updateOrdersInput?: InputMaybe<UpdateOrdersInput>;
};


export type MutationUpdateOutOfStockTextByColorArgs = {
  outOfStockTextByColor?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  selectedColor?: InputMaybe<Scalars['String']>;
  selectedWarehouse?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePaymentMethodsOfCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePreOrderWindowArgs = {
  preOrderWindowInput?: InputMaybe<PreOrderWindowInput>;
};


export type MutationUpdatePricesArgs = {
  updatePriceInput?: InputMaybe<UpdatePriceInput>;
};


export type MutationUpdateProductDetailsArgs = {
  outOfStockText?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  productLabel?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductListOfAdminArgs = {
  adminId?: InputMaybe<Scalars['String']>;
  productListId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductListOfCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  productListId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductListsOfCourseArgs = {
  courseId?: InputMaybe<Scalars['String']>;
  productListId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductRankArgs = {
  environmentId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateProductsArgs = {
  updateProductsInput?: InputMaybe<UpdateProductsInput>;
};


export type MutationUpdateQuestionArgs = {
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateSalesPersonForCompanyArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  salesPersonId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateShippingForCompanyArgs = {
  shippingFee?: InputMaybe<Scalars['Float']>;
  shippingFreeAbove?: InputMaybe<Scalars['Float']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateShouldSendEmailConfrimationArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  shouldSendConfirmationEmails?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateSkuAccessArgs = {
  accesses?: InputMaybe<Scalars['String']>;
  skuId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateSkuColorRankArgs = {
  color?: InputMaybe<Scalars['String']>;
  colorRank?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateSkusArgs = {
  updateSkusInput?: InputMaybe<UpdateSkusInput>;
};


export type MutationUpdateSkusInventoryArgs = {
  updateSkusInventoryInput?: InputMaybe<UpdateSkusInventoryInput>;
};


export type MutationUpdateSkusProductListsArgs = {
  updateSkusProductListsInput?: InputMaybe<UpdateSkusProductListsInput>;
};


export type MutationUpdateStatusOfCustomerArgs = {
  status?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStockTextArgs = {
  input?: InputMaybe<StockMultipleInput>;
};


export type MutationUpdateStoreWebsiteDataArgs = {
  id?: InputMaybe<Scalars['String']>;
  officialShippingAddressLine1?: InputMaybe<Scalars['String']>;
  officialShippingCity?: InputMaybe<Scalars['String']>;
  officialShippingCountry?: InputMaybe<Scalars['String']>;
  officialShippingState?: InputMaybe<Scalars['String']>;
  officialShippingZipCode?: InputMaybe<Scalars['String']>;
  officialStoreName?: InputMaybe<Scalars['String']>;
  officialStorePhone?: InputMaybe<Scalars['String']>;
  showOnMap?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateVatInformationForCompanyArgs = {
  env?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateVatNumberArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  VATNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateWarehouseRankArgs = {
  sortNumber?: InputMaybe<Scalars['Int']>;
  warehouseId?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  acknowledged?: Maybe<Array<Maybe<User>>>;
  acknowledgedAdmin?: Maybe<Scalars['Boolean']>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  forAdmin?: Maybe<Admin>;
  forAdminId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  type?: Maybe<NotificationType>;
};

export type NotificationsInput = {
  environments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  productLists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<NotificationType>;
};

export enum NotificationType {
  Alert = 'ALERT',
  AlertAdmin = 'ALERT_ADMIN',
  Basic = 'BASIC',
  Login = 'LOGIN'
}

export type NumberOfOrdersPage = {
  __typename?: 'NumberOfOrdersPage';
  statuses?: Maybe<Array<Maybe<StatusType>>>;
};

export type NumbersDataPerMonth = {
  __typename?: 'NumbersDataPerMonth';
  numberOfActiveStores?: Maybe<Scalars['Int']>;
  numberOfActiveStoresWithOrderLastMonth?: Maybe<Scalars['Int']>;
  numberOfStoresCalledLastMonth?: Maybe<Scalars['Int']>;
  numberOfStoresEventsLastMonth?: Maybe<Scalars['Int']>;
  numberOfStoresVistedLastMonth?: Maybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['date']>;
  discountedBy?: Maybe<Scalars['Float']>;
  discountUpdated?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<Scalars['Boolean']>;
  editedBy?: Maybe<OrderEditedBy>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  isInSync?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  lastModifiedAt?: Maybe<Scalars['date']>;
  orderCountry?: Maybe<Country>;
  orderCountryId?: Maybe<Scalars['String']>;
  orderCreatedBy?: Maybe<OrderCreatedBy>;
  orderOrigin?: Maybe<OrderOriginType>;
  orderSentToVismaCount?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['Boolean']>;
  preOrderWindow?: Maybe<PreOrderWindow>;
  preOrderWindowId?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<ReasonCode>;
  reasonCodeId?: Maybe<Scalars['String']>;
  salesNotifications?: Maybe<Array<Maybe<SalesNotification>>>;
  scheduledShipmentDate?: Maybe<Scalars['date']>;
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  shipmentType?: Maybe<OrderShipmentType>;
  shipping?: Maybe<Shipping>;
  shippingCost?: Maybe<Scalars['Float']>;
  shippingId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
  storeOrderId?: Maybe<Scalars['String']>;
  stripeOrderInvoice?: Maybe<StripeOrderInvoice>;
  stripeOrderPaymentLink?: Maybe<StripeOrderPaymentLink>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalVAT?: Maybe<Scalars['Float']>;
  trackingLink?: Maybe<Scalars['String']>;
  type?: Maybe<OrderType>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  warehouse?: Maybe<Warehouse>;
  warehouseId?: Maybe<Scalars['String']>;
};


export type OrderItemsArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};

export type OrderCreatedBy = {
  __typename?: 'OrderCreatedBy';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserOrAdmin>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderEditedBy = {
  __typename?: 'OrderEditedBy';
  admin?: Maybe<Array<Maybe<Admin>>>;
  adminId?: Maybe<Scalars['String']>;
  editedBy?: Maybe<UserOrAdmin>;
  id?: Maybe<Scalars['String']>;
  lastEdit?: Maybe<Scalars['date']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt?: Maybe<Scalars['date']>;
  customPrice?: Maybe<Scalars['Float']>;
  disabled?: Maybe<Scalars['Boolean']>;
  discountedBy?: Maybe<Scalars['Float']>;
  discountLock?: Maybe<Scalars['Boolean']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  item?: Maybe<Sku>;
  manuallyDiscounted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityShipped?: Maybe<Scalars['Int']>;
  reasonCode?: Maybe<ReasonCodeLineItem>;
  reasonCodeId?: Maybe<Scalars['String']>;
  reasonCodeLineItem?: Maybe<ReasonCode>;
  shipmentType?: Maybe<OrderShipmentType>;
  skuId?: Maybe<Scalars['String']>;
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['String']>;
  totalCreditAmount?: Maybe<Scalars['Float']>;
};


export type OrderItemItemArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
};

export type OrderItemInput = {
  discount?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  quantityShipped?: InputMaybe<Scalars['Int']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  shipmentType?: InputMaybe<OrderShipmentType>;
  sku?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  vismaItemNumber?: InputMaybe<Scalars['String']>;
};

export enum OrderOriginType {
  B2BPortal = 'B2B_PORTAL',
  Pp = 'PP',
  Pr = 'PR',
  Re = 'RE',
  Salesorder = 'SALESORDER',
  Sf = 'SF',
  Sk = 'SK',
  Sponsored = 'SPONSORED',
  TestProducts = 'TEST_PRODUCTS'
}

export type OrderPage = {
  __typename?: 'OrderPage';
  orders?: Maybe<Array<Maybe<Order>>>;
  totalNumberOfOrders?: Maybe<Scalars['Int']>;
};

export type OrderPageFilters = {
  __typename?: 'OrderPageFilters';
  countries?: Maybe<Array<Maybe<CountryType>>>;
  states?: Maybe<Array<Maybe<StateType>>>;
  statuses?: Maybe<Array<Maybe<StatusFilter>>>;
};

export type OrderShipment = {
  __typename?: 'OrderShipment';
  carrier?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePDF?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  trackingLink?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderShipmentInput = {
  carrier?: InputMaybe<Scalars['String']>;
  externalRef?: InputMaybe<Scalars['String']>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  trackingNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum OrderShipmentType {
  BackOrderAllowed = 'BACK_ORDER_ALLOWED',
  CancelRemainder = 'CANCEL_REMAINDER',
  ShipWhenComplete = 'SHIP_WHEN_COMPLETE'
}

export type OrderShipmentVisma = {
  __typename?: 'OrderShipmentVisma';
  confirmed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type OrdersInput = {
  country?: InputMaybe<Scalars['String']>;
  currentAdmin?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  perPage?: InputMaybe<Scalars['String']>;
  placedBy?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export enum OrderStatus {
  Active = 'ACTIVE',
  BackOrder = 'BACK_ORDER',
  BetweenStages = 'BETWEEN_STAGES',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  CreditHold = 'CREDIT_HOLD',
  Hold = 'HOLD',
  Invoiced = 'INVOICED',
  Local = 'LOCAL',
  Open = 'OPEN',
  Packing = 'PACKING',
  Pending = 'PENDING',
  PendingApproval = 'PENDING_APPROVAL',
  Shipping = 'SHIPPING',
  Voided = 'VOIDED'
}

export type OrdersType = {
  __typename?: 'OrdersType';
  createdAt?: Maybe<Scalars['String']>;
};

export enum OrderType {
  CreditNote = 'CREDIT_NOTE',
  Order = 'ORDER',
  PreOrder = 'PRE_ORDER'
}

export type OrderTypes = {
  __typename?: 'OrderTypes';
  order?: Maybe<Order>;
  totalNumberOfOrderItems?: Maybe<Scalars['Int']>;
};

export type PaginatedOrderPage = {
  __typename?: 'PaginatedOrderPage';
  orders?: Maybe<Array<Maybe<Order>>>;
  totalNumberOfOrders?: Maybe<Scalars['Int']>;
};

export enum PaymentMethods {
  Adyen = 'ADYEN',
  Invoice = 'INVOICE'
}

export enum PaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type Permission = {
  __typename?: 'Permission';
  admins?: Maybe<Array<Maybe<Admin>>>;
  id?: Maybe<Scalars['String']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PerMonth = {
  __typename?: 'PerMonth';
  data?: Maybe<NumbersDataPerMonth>;
  month?: Maybe<Scalars['String']>;
};

export type PlaceOrder = {
  __typename?: 'PlaceOrder';
  counter?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  removedSkus?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlaceOrderInput = {
  comment?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  onHold?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['String']>;
  storeOrderId?: InputMaybe<Scalars['String']>;
};

export type PreOrderInventory = {
  __typename?: 'PreOrderInventory';
  id?: Maybe<Scalars['String']>;
  inventory?: Maybe<Scalars['Int']>;
  preOrderProduct?: Maybe<PreOrderProduct>;
  preOrderProductId?: Maybe<Scalars['String']>;
  sku?: Maybe<Sku>;
  skuId?: Maybe<Scalars['String']>;
};

export type PreOrderProduct = {
  __typename?: 'PreOrderProduct';
  id?: Maybe<Scalars['String']>;
  inventory?: Maybe<Array<Maybe<PreOrderInventory>>>;
  preOrderInventoryId?: Maybe<Scalars['String']>;
  PreOrderWindow?: Maybe<PreOrderWindow>;
  preOrderWindowId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
};

export type PreOrderProductInput = {
  productId?: InputMaybe<Scalars['String']>;
  skus?: InputMaybe<Array<InputMaybe<PreOrderSkuInventoryInput>>>;
};

export type PreOrderSkuInventoryInput = {
  inventory?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type PreOrderWindow = {
  __typename?: 'PreOrderWindow';
  discountPercentage?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['date']>;
  freeShippingOver?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  preOrderProduct?: Maybe<Array<Maybe<PreOrderProduct>>>;
  startDate?: Maybe<Scalars['date']>;
};

export type PreOrderWindowInput = {
  discountPercentage?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['date']>;
  freeShippingOver?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<PreOrderProductInput>>>;
  startDate?: InputMaybe<Scalars['date']>;
};

export type Price = {
  __typename?: 'Price';
  baseAmount?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<BasePrice>;
  basePriceId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  retailPrice?: Maybe<Scalars['Float']>;
  sku?: Maybe<Sku>;
  skuId?: Maybe<Scalars['String']>;
};

export type PriceInput = {
  baseAmount?: InputMaybe<Scalars['Float']>;
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  retailPrice?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type PriceListLink = {
  __typename?: 'PriceListLink';
  basePrice?: Maybe<BasePrice>;
  basePriceId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type PricesAndStocks = {
  __typename?: 'PricesAndStocks';
  prices?: Maybe<Array<Maybe<Price>>>;
  stocks?: Maybe<Array<Maybe<Stock>>>;
};

export type PricesBySkuInput = {
  skuIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Product = {
  __typename?: 'Product';
  additionalImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowOverride?: Maybe<Scalars['Boolean']>;
  applyDiscount?: Maybe<Scalars['Boolean']>;
  boosted?: Maybe<Scalars['Boolean']>;
  boostedForStores?: Maybe<Array<Maybe<Store>>>;
  boostedProduct?: Maybe<Array<Maybe<BoostedProduct>>>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  crossSale?: Maybe<CrossSale>;
  description?: Maybe<Scalars['String']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  label?: Maybe<ProductLabel>;
  lastWeekSales?: Maybe<Scalars['Int']>;
  measures?: Maybe<Array<Maybe<Measure>>>;
  name?: Maybe<Scalars['String']>;
  outOfStockText?: Maybe<Scalars['String']>;
  productBackInStock?: Maybe<ProductBackInStock>;
  productForBreed?: Maybe<Array<Maybe<ProductForBreed>>>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  productSuggestedOn?: Maybe<CrossSale>;
  rank?: Maybe<Scalars['Int']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  slug?: Maybe<Scalars['String']>;
  sortValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  specifications?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  translations?: Maybe<Array<Maybe<Translate>>>;
};

export type ProductBackInStock = {
  __typename?: 'ProductBackInStock';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type ProductById = {
  __typename?: 'ProductById';
  product?: Maybe<Product>;
  skus?: Maybe<Array<Maybe<Sku>>>;
};

export type ProductForBreed = {
  __typename?: 'ProductForBreed';
  adjustment?: Maybe<Scalars['Float']>;
  breed?: Maybe<Breed>;
  breedId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['Float']>;
  log?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  standardDeviation?: Maybe<Scalars['Float']>;
};

export enum ProductLabel {
  ComingSoon = 'COMING_SOON',
  New = 'NEW',
  NoLabel = 'NO_LABEL',
  PopularNow = 'POPULAR_NOW'
}

export type ProductList = {
  __typename?: 'ProductList';
  admins?: Maybe<Admin>;
  companies?: Maybe<Array<Maybe<Company>>>;
  courses?: Maybe<Array<Maybe<Course>>>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  external_ref?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  vismaValueId?: Maybe<Scalars['String']>;
};

export type ProductListsInput = {
  environment?: InputMaybe<Scalars['String']>;
  productLists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sku?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adminGetOrdersPage?: Maybe<OrderPage>;
  adminGetOrdersPageFilters?: Maybe<OrderPageFilters>;
  adminOrder?: Maybe<Order>;
  adminOrderItems?: Maybe<OrderTypes>;
  allAdmins?: Maybe<Array<Maybe<Admin>>>;
  allApplicants?: Maybe<Array<Maybe<Company>>>;
  allBasePricesByEnvironment?: Maybe<Array<Maybe<BasePrice>>>;
  allBreeds?: Maybe<Array<Maybe<Breed>>>;
  allCurrencies?: Maybe<Array<Maybe<Currency>>>;
  allNotifications?: Maybe<Array<Maybe<Notification>>>;
  allPreOrderWindows?: Maybe<Array<Maybe<PreOrderWindow>>>;
  allPriceListLinksByEnvironment?: Maybe<Array<Maybe<PriceListLink>>>;
  allProductLists?: Maybe<Array<Maybe<ProductList>>>;
  allProducts?: Maybe<Array<Maybe<Product>>>;
  allSkus?: Maybe<Array<Maybe<Sku>>>;
  allStores?: Maybe<Array<Maybe<Store>>>;
  allStoresStatic?: Maybe<Array<Maybe<Store>>>;
  allUsers?: Maybe<Array<Maybe<User>>>;
  boostedProducts?: Maybe<Array<Maybe<Product>>>;
  boostedProductsForStore?: Maybe<Array<Maybe<Product>>>;
  calculateOrderTotalQuery?: Maybe<Order>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoriesStatic?: Maybe<Array<Maybe<CategoriesStatic>>>;
  checkIfUserExists?: Maybe<Scalars['Boolean']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  findCompaniesWithPayments?: Maybe<Message>;
  findMissingStripeCustomersIdUsers?: Maybe<Array<Maybe<User>>>;
  findSize?: Maybe<Array<Maybe<Size>>>;
  getAdminData?: Maybe<AdminPage>;
  getAllBanners?: Maybe<Array<Maybe<Banner>>>;
  getAllCampaigns?: Maybe<Array<Maybe<Campaign>>>;
  getAllChains?: Maybe<Array<Maybe<Chain>>>;
  getAllChainsByEnvironmentId?: Maybe<Array<Maybe<Chain>>>;
  getAllCompaniesForCampaign?: Maybe<Array<Maybe<Company>>>;
  getAllCountries?: Maybe<Array<Maybe<Country>>>;
  getAllCourses?: Maybe<Array<Maybe<Course>>>;
  getAllEnvironments?: Maybe<Array<Maybe<Environment>>>;
  getAllPermissions?: Maybe<Array<Maybe<Permission>>>;
  getAllProductsForCampaign?: Maybe<Array<Maybe<Product>>>;
  getAllStates?: Maybe<Array<Maybe<State>>>;
  getAllTrainingQuestions?: Maybe<Array<Maybe<TrainingQuestion>>>;
  getAllUsers?: Maybe<Array<Maybe<User>>>;
  getAllWarehouses?: Maybe<Array<Maybe<Warehouse>>>;
  getCampaign?: Maybe<Campaign>;
  getColors?: Maybe<Array<Maybe<Color>>>;
  getCurrentAdmin?: Maybe<MeAdmin>;
  getCurrentEnvironment?: Maybe<Environment>;
  getCurrentOrder?: Maybe<Order>;
  getCurrentUser?: Maybe<Me>;
  getCurrentUserOrder?: Maybe<Order>;
  getCustomersPage?: Maybe<CustomersPage>;
  getDuplicateLocalOrders?: Maybe<Message>;
  getInvoices?: Maybe<Array<Maybe<Invoice>>>;
  getInvoicesByCompanyId?: Maybe<Array<Maybe<Invoice>>>;
  getLastCreatedSetting?: Maybe<Settings>;
  getListOfAdmins?: Maybe<Array<Maybe<Admin>>>;
  getListOfCustomers?: Maybe<Message>;
  getListOfProducts?: Maybe<Array<Maybe<Product>>>;
  getOrdersByCountry?: Maybe<Scalars['Int']>;
  getOrdersPlacedBy?: Maybe<Array<Maybe<Order>>>;
  getPermission?: Maybe<Message>;
  getPreOrderWindow?: Maybe<PreOrderWindow>;
  getProductList?: Maybe<ProductList>;
  getProductsWithWrongSlug?: Maybe<Array<Maybe<Product>>>;
  getReasonCodes?: Maybe<ReasonCodePage>;
  getReportForCampaing?: Maybe<Message>;
  getSingleCourse?: Maybe<Course>;
  getSkusPage?: Maybe<SkusPage>;
  getSortValuesForSkus?: Maybe<StringArray>;
  getStates?: Maybe<Array<Maybe<State>>>;
  getTotalAmountOfInvoices?: Maybe<Scalars['Int']>;
  getTotalLastContacted?: Maybe<Scalars['Int']>;
  getUserCourses?: Maybe<Array<Maybe<UserCourse>>>;
  getVatInformation?: Maybe<VatInformation>;
  getWarehouses?: Maybe<Array<Maybe<Warehouse>>>;
  inventoryPage?: Maybe<InventoryPage>;
  kpi?: Maybe<Kpi>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  order?: Maybe<Order>;
  orderItemByOrderId?: Maybe<Array<Maybe<OrderItem>>>;
  ordersByStore?: Maybe<Array<Maybe<Order>>>;
  ordersByStoreId?: Maybe<Array<Maybe<Order>>>;
  ordersByUser?: Maybe<Array<Maybe<Order>>>;
  orderShipmentVisma?: Maybe<OrderShipmentVisma>;
  paginatedOrdersForStore?: Maybe<Array<Maybe<PaginatedOrderPage>>>;
  pricesBySku?: Maybe<Array<Maybe<Price>>>;
  pricesForSkusAndStocks?: Maybe<PricesAndStocks>;
  productById?: Maybe<ProductById>;
  productByName?: Maybe<Product>;
  productListsByUser?: Maybe<Company>;
  productsByCategory?: Maybe<Array<Maybe<Product>>>;
  productsByCategoryStatic?: Maybe<Array<Maybe<Product>>>;
  reports?: Maybe<Array<Maybe<Order>>>;
  salesNotifications?: Maybe<Array<Maybe<SalesNotification>>>;
  searchActiveStores?: Maybe<Array<Maybe<Store>>>;
  searchProducts?: Maybe<Array<Maybe<Product>>>;
  searchSkus?: Maybe<Array<Maybe<Sku>>>;
  searchStores?: Maybe<Array<Maybe<Store>>>;
  selectedBreed?: Maybe<Array<Maybe<Breed>>>;
  shippings?: Maybe<ShippingsPage>;
  singleApplicant?: Maybe<Company>;
  sizeFinder?: Maybe<SizeFinder>;
  skuById?: Maybe<Sku>;
  skusByProduct?: Maybe<Array<Maybe<Sku>>>;
  skusForProductPortal?: Maybe<Array<Maybe<Sku>>>;
  storeById?: Maybe<Store>;
  storeByIdStatic?: Maybe<Store>;
  storeByUser?: Maybe<Store>;
  unPurchasedProducts?: Maybe<Array<Maybe<Product>>>;
  unreadNotificationsCount?: Maybe<SalesNotificationCount>;
  usersByStore?: Maybe<Array<Maybe<User>>>;
};


export type QueryAdminGetOrdersPageArgs = {
  input?: InputMaybe<OrdersInput>;
};


export type QueryAdminGetOrdersPageFiltersArgs = {
  input?: InputMaybe<OrdersInput>;
};


export type QueryAdminOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type QueryAdminOrderItemsArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type QueryAllAdminsArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryAllBasePricesByEnvironmentArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryAllBreedsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryAllPriceListLinksByEnvironmentArgs = {
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryBoostedProductsForStoreArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryCalculateOrderTotalQueryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryCategoriesStaticArgs = {
  accessPortal?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCheckIfUserExistsArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryFindSizeArgs = {
  breed?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};


export type QueryGetAdminDataArgs = {
  perPage?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllCampaignsArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllChainsArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllChainsByEnvironmentIdArgs = {
  environmentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllCompaniesForCampaignArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllCountriesArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllCoursesArgs = {
  isPublished?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAllProductsForCampaignArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllStatesArgs = {
  country?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllTrainingQuestionsArgs = {
  status?: InputMaybe<TrainingQuestionStatus>;
};


export type QueryGetAllUsersArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryGetCampaignArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetCurrentAdminArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetCurrentOrderArgs = {
  preOrderWindowId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCurrentUserArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetCustomersPageArgs = {
  input?: InputMaybe<CustomersInput>;
};


export type QueryGetInvoicesByCompanyIdArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  perPage?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
};


export type QueryGetListOfCustomersArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrdersByCountryArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type QueryGetPreOrderWindowArgs = {
  preOrderWindowId?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductListArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetReasonCodesArgs = {
  environment?: InputMaybe<Scalars['String']>;
  isCreditNote?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetReportForCampaingArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetSingleCourseArgs = {
  teachableId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSkusPageArgs = {
  skusArray?: InputMaybe<Scalars['String']>;
};


export type QueryGetSortValuesForSkusArgs = {
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalAmountOfInvoicesArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalLastContactedArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetVatInformationArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


export type QueryGetWarehousesArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type QueryInventoryPageArgs = {
  filterIncomingInventory?: InputMaybe<Scalars['String']>;
  inventoryNumber?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  warehouse?: InputMaybe<Scalars['String']>;
};


export type QueryKpiArgs = {
  template?: InputMaybe<Scalars['String']>;
};


export type QueryOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type QueryOrderItemByOrderIdArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type QueryOrdersByStoreIdArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryOrdersByUserArgs = {
  status?: InputMaybe<Scalars['String']>;
};


export type QueryOrderShipmentVismaArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedOrdersForStoreArgs = {
  perPage?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryPricesBySkuArgs = {
  skuId?: InputMaybe<Scalars['String']>;
};


export type QueryPricesForSkusAndStocksArgs = {
  input?: InputMaybe<PricesBySkuInput>;
};


export type QueryProductByIdArgs = {
  preOrderWindowId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryProductByNameArgs = {
  productName?: InputMaybe<Scalars['String']>;
};


export type QueryProductsByCategoryArgs = {
  categoryName?: InputMaybe<Scalars['String']>;
};


export type QueryProductsByCategoryStaticArgs = {
  access?: InputMaybe<Scalars['Boolean']>;
  accessPortal?: InputMaybe<Scalars['Boolean']>;
  categoryName?: InputMaybe<Scalars['String']>;
};


export type QueryReportsArgs = {
  maxDate?: InputMaybe<Scalars['String']>;
  minDate?: InputMaybe<Scalars['String']>;
};


export type QuerySearchActiveStoresArgs = {
  countries?: InputMaybe<Scalars['String']>;
  hideRegistered?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySearchProductsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySearchSkusArgs = {
  access?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySearchStoresArgs = {
  countries?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  hideRegistered?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySelectedBreedArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySingleApplicantArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySkuByIdArgs = {
  skuId?: InputMaybe<Scalars['String']>;
};


export type QuerySkusByProductArgs = {
  access?: InputMaybe<Scalars['Boolean']>;
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};


export type QuerySkusForProductPortalArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  secondFilter?: InputMaybe<Scalars['String']>;
};


export type QueryStoreByIdArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryStoreByIdStaticArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryUnPurchasedProductsArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};


export type QueryUsersByStoreArgs = {
  storeId?: InputMaybe<Scalars['String']>;
};

export type ReasonCode = {
  __typename?: 'ReasonCode';
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderItems?: Maybe<OrderItem>;
  orders?: Maybe<Order>;
};

export enum ReasonCodeLineItem {
  Ads = 'ADS',
  Athlets = 'ATHLETS',
  B2Bspons = 'B2BSPONS',
  Canix = 'CANIX',
  Clubs = 'CLUBS',
  Concreat = 'CONCREAT',
  Content = 'CONTENT',
  Defense = 'DEFENSE',
  Event = 'EVENT',
  Gift = 'GIFT',
  Giveaway = 'GIVEAWAY',
  Hunter = 'HUNTER',
  Influencer = 'INFLUENCER',
  Musher = 'MUSHER',
  Nine = 'NINE',
  One = 'ONE',
  Otherecom = 'OTHERECOM',
  Paidcol1 = 'PAIDCOL1',
  Paidcol2 = 'PAIDCOL2',
  Qrill = 'QRILL',
  Sms = 'SMS',
  Spons = 'SPONS',
  Test = 'TEST',
  Trbuddy = 'TRBUDDY',
  Two = 'TWO',
  Ukuruans = 'UKURUANS'
}

export type ReasonCodePage = {
  __typename?: 'ReasonCodePage';
  reasonCodes?: Maybe<Array<Maybe<ReasonCodeType>>>;
};

export type ReasonCodeType = {
  __typename?: 'ReasonCodeType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RegisterCustomerInput = {
  applicantComment?: InputMaybe<Scalars['String']>;
  attention?: InputMaybe<Scalars['String']>;
  billingAttention?: InputMaybe<Scalars['String']>;
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  billingLine1?: InputMaybe<Scalars['String']>;
  billingLine2?: InputMaybe<Scalars['String']>;
  billingPhone?: InputMaybe<Scalars['String']>;
  billingState?: InputMaybe<Scalars['String']>;
  billingZipCode?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporateID?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  deliveryContactIsSameAsMain?: InputMaybe<Scalars['Boolean']>;
  deliveryIsSameAsMain?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  invoiceAttention?: InputMaybe<Scalars['String']>;
  invoiceCity?: InputMaybe<Scalars['String']>;
  invoiceComapnyName?: InputMaybe<Scalars['String']>;
  invoiceContactIsSameAsMain?: InputMaybe<Scalars['Boolean']>;
  invoiceCountry?: InputMaybe<Scalars['String']>;
  invoiceEmail?: InputMaybe<Scalars['String']>;
  invoiceIsSameAsMain?: InputMaybe<Scalars['Boolean']>;
  invoiceLine1?: InputMaybe<Scalars['String']>;
  invoiceLine2?: InputMaybe<Scalars['String']>;
  invoicePhone?: InputMaybe<Scalars['String']>;
  invoiceState?: InputMaybe<Scalars['String']>;
  invoiceZipCode?: InputMaybe<Scalars['String']>;
  isDefense?: InputMaybe<Scalars['Boolean']>;
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationNumber?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingPhone?: InputMaybe<Scalars['String']>;
  shouldSendConfirmationEmails?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
  VATNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type SalesNotification = {
  __typename?: 'SalesNotification';
  admins?: Maybe<Array<Maybe<Admin>>>;
  body?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
};

export type SalesNotificationCount = {
  __typename?: 'SalesNotificationCount';
  count?: Maybe<Scalars['Int']>;
};

export type SelectedCustomers = {
  selectedCustomers?: InputMaybe<Array<InputMaybe<SelectedCustomersInput>>>;
};

export type SelectedCustomersInput = {
  external_ref?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  id?: Maybe<Scalars['String']>;
  maintenanceMode?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['date']>;
};

export type Shipping = {
  __typename?: 'Shipping';
  basePrices?: Maybe<Array<Maybe<BasePrice>>>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  freeAbove?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type ShippingInput = {
  basePriceId?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Float']>;
  freeAbove?: InputMaybe<Scalars['Float']>;
  shippingId?: InputMaybe<Scalars['String']>;
};

export type ShippingsPage = {
  __typename?: 'ShippingsPage';
  basePrices?: Maybe<Array<Maybe<BasePrice>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  shippings?: Maybe<Array<Maybe<Shipping>>>;
};

export type Size = {
  __typename?: 'Size';
  cannotRecommend?: Maybe<Scalars['Boolean']>;
  firstDecimal?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  secondSize?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  sizesNotAvailable?: Maybe<Array<Maybe<Scalars['Int']>>>;
  standardDeviation?: Maybe<Scalars['Float']>;
};

export type SizeFinder = {
  __typename?: 'SizeFinder';
  breeds?: Maybe<Array<Maybe<Breed>>>;
  productsWithLetterSize?: Maybe<Array<Maybe<Product>>>;
  productsWithNumberSize?: Maybe<Array<Maybe<Product>>>;
  sizesNotAvailableForProduct?: Maybe<Array<Maybe<SizesNotAvailableForProduct>>>;
};

export type SizesNotAvailableForProduct = {
  __typename?: 'SizesNotAvailableForProduct';
  productId?: Maybe<Scalars['String']>;
  sizesNotAvailable?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Sku = {
  __typename?: 'Sku';
  access?: Maybe<Array<Maybe<EntityAccess>>>;
  allowOverride?: Maybe<Scalars['Boolean']>;
  backToStockActive?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  colorRank?: Maybe<Scalars['Int']>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  harness?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  inventory?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  outOfStockText?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  prices?: Maybe<Array<Maybe<Price>>>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  productLists?: Maybe<Array<Maybe<ProductList>>>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  skuBackInStock?: Maybe<SkuBackInStock>;
  status?: Maybe<Status>;
  stock?: Maybe<Array<Maybe<Stock>>>;
  version?: Maybe<Scalars['String']>;
};


export type SkuPriceArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};


export type SkuPricesArgs = {
  basePrice?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
};

export type SkuBackInStock = {
  __typename?: 'SkuBackInStock';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Sku>;
  skuId?: Maybe<Scalars['String']>;
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type SkusInput = {
  country?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  inventory?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['String']>;
};

export type SkusPage = {
  __typename?: 'SkusPage';
  skus?: Maybe<Array<Maybe<SkusType>>>;
};

export type SkusType = {
  __typename?: 'SkusType';
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  skuId?: Maybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  admins?: Maybe<Array<Maybe<Admin>>>;
  code?: Maybe<Scalars['String']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export type StateType = {
  __typename?: 'StateType';
  id?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export enum Status {
  Discontinued = 'DISCONTINUED',
  InProduction = 'IN_PRODUCTION'
}

export type StatusFilter = {
  __typename?: 'StatusFilter';
  length?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type StatusType = {
  __typename?: 'StatusType';
  length?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type Stock = {
  __typename?: 'Stock';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomingInventory?: Maybe<Scalars['Int']>;
  inventory?: Maybe<Scalars['Int']>;
  orderItems?: Maybe<Array<Maybe<OrderItem>>>;
  outOfStockText?: Maybe<Scalars['String']>;
  sku?: Maybe<Sku>;
  skuId?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Warehouse>;
  warehouseId?: Maybe<Scalars['String']>;
};

export type StockInput = {
  id?: InputMaybe<Scalars['String']>;
  inventory?: InputMaybe<Scalars['Int']>;
  outOfStockText?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['String']>;
};

export type StockMultipleInput = {
  stocks?: InputMaybe<Array<InputMaybe<StockInput>>>;
};

export type Store = {
  __typename?: 'Store';
  access?: Maybe<Array<Maybe<EntityAccess>>>;
  admins?: Maybe<Array<Maybe<Admin>>>;
  attention?: Maybe<Scalars['String']>;
  basePrice?: Maybe<BasePrice>;
  basePriceId?: Maybe<Scalars['String']>;
  boostedProducts?: Maybe<Array<Maybe<Product>>>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerCountry?: Maybe<Country>;
  customerCountryId?: Maybe<Scalars['String']>;
  customerState?: Maybe<State>;
  customerStateId?: Maybe<Scalars['String']>;
  duplicateUsers?: Maybe<Scalars['String']>;
  event?: Maybe<Array<Maybe<Event>>>;
  external_ref?: Maybe<Scalars['String']>;
  followers?: Maybe<Array<Maybe<Admin>>>;
  holdOrders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isInSaleslayer?: Maybe<Scalars['Boolean']>;
  lastCall?: Maybe<Array<Maybe<LastCall>>>;
  lastCallDate?: Maybe<Scalars['date']>;
  lastEvent?: Maybe<Array<Maybe<Event>>>;
  lastEventDate?: Maybe<Scalars['date']>;
  lastOrderDate?: Maybe<Scalars['date']>;
  lastVisit?: Maybe<Array<Maybe<LastVisit>>>;
  lastVisitDate?: Maybe<Scalars['date']>;
  latitude?: Maybe<Scalars['Float']>;
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  officialShippingAddressLine1?: Maybe<Scalars['String']>;
  officialShippingCity?: Maybe<Scalars['String']>;
  officialShippingCountry?: Maybe<Scalars['String']>;
  officialShippingState?: Maybe<Scalars['String']>;
  officialShippingZipCode?: Maybe<Scalars['String']>;
  officialStoreName?: Maybe<Scalars['String']>;
  officialStorePhone?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  priority?: Maybe<StorePriority>;
  shippingEmail?: Maybe<Scalars['String']>;
  shippingFee?: Maybe<Scalars['Float']>;
  shippingFreeAbove?: Maybe<Scalars['Float']>;
  shippingPhone?: Maybe<Scalars['String']>;
  showOnMap?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<EntityStatus>;
  termsAndConditions?: Maybe<TermsAndConditions>;
  termsAndConditionsId?: Maybe<Scalars['String']>;
  totalSalesCurrentYear?: Maybe<Scalars['Float']>;
  totalSalesCurrentYearVAT?: Maybe<Scalars['Float']>;
  totalSalesPreviousYear?: Maybe<Scalars['Float']>;
  users?: Maybe<Array<Maybe<User>>>;
  warehouse?: Maybe<Warehouse>;
  warehouseId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type StoreCustomerInput = {
  access?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attention?: InputMaybe<Scalars['String']>;
  basePrice?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingPhone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EntityStatus>;
  store?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<UserCustomerInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type StoreInput = {
  basePrice?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  externalRef?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export enum StorePriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type StringArray = {
  __typename?: 'StringArray';
  shouldUse?: Maybe<Scalars['Boolean']>;
  strings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StripeOrderInvoice = {
  __typename?: 'StripeOrderInvoice';
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoicePdfUrl?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
};

export type StripeOrderPaymentLink = {
  __typename?: 'StripeOrderPaymentLink';
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  paymentLink?: Maybe<Scalars['String']>;
  paymentLinkId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type TrainingQuestion = {
  __typename?: 'TrainingQuestion';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  status?: Maybe<TrainingQuestionStatus>;
};

export enum TrainingQuestionStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Translate = {
  __typename?: 'Translate';
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type UpdateCustomersInput = {
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  billingLine1?: InputMaybe<Scalars['String']>;
  billingLine2?: InputMaybe<Scalars['String']>;
  billingPhone?: InputMaybe<Scalars['String']>;
  billingZipCode?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  customerDefinition?: InputMaybe<CustomerDefinition>;
  customerType?: InputMaybe<CustomerType>;
  environment?: InputMaybe<Scalars['String']>;
  generalInfoCity?: InputMaybe<Scalars['String']>;
  generalInfoCountry?: InputMaybe<Scalars['String']>;
  generalInfoEmail?: InputMaybe<Scalars['String']>;
  generalInfoLine1?: InputMaybe<Scalars['String']>;
  generalInfoLine2?: InputMaybe<Scalars['String']>;
  generalInfoPhone?: InputMaybe<Scalars['String']>;
  generalInfoZipCode?: InputMaybe<Scalars['String']>;
  isTaxExempt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationNumber?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  priority?: InputMaybe<CompanyPriority>;
  productLists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  responsiblePerson?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EntityStatus>;
  stores?: InputMaybe<Array<InputMaybe<StoreCustomerInput>>>;
  vatZone?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['String']>;
};

export type UpdateDataCustomerInput = {
  accesses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attention?: InputMaybe<Scalars['String']>;
  basePrice?: InputMaybe<Scalars['String']>;
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  billingLine1?: InputMaybe<Scalars['String']>;
  billingPhone?: InputMaybe<Scalars['String']>;
  billingState?: InputMaybe<Scalars['String']>;
  billingZipCode?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerDefinition?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  generalInfoCity?: InputMaybe<Scalars['String']>;
  generalInfoCountry?: InputMaybe<Scalars['String']>;
  generalInfoEmail?: InputMaybe<Scalars['String']>;
  generalInfoLine1?: InputMaybe<Scalars['String']>;
  generalInfoPhone?: InputMaybe<Scalars['String']>;
  generalInfoState?: InputMaybe<Scalars['String']>;
  generalInfoZipCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  line_1?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  responsiblePerson?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingPhone?: InputMaybe<Scalars['String']>;
  shouldUpdateMainContact?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateDiscountsInput = {
  discounts?: InputMaybe<Array<InputMaybe<DiscountInput>>>;
};

export type UpdateOrderInput = {
  admin?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  customerComment?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  externalRef?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<OrderItemInput>>>;
  lastModifiedAt?: InputMaybe<Scalars['String']>;
  orderOrigin?: InputMaybe<Scalars['String']>;
  schedShipment?: InputMaybe<Scalars['String']>;
  shipments?: InputMaybe<Array<InputMaybe<OrderShipmentInput>>>;
  shipmentType?: InputMaybe<OrderShipmentType>;
  shippingCost?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrderStatus>;
  store?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['String']>;
};

export type UpdateOrdersInput = {
  orders?: InputMaybe<Array<InputMaybe<UpdateOrderInput>>>;
};

export type UpdatePriceInput = {
  prices?: InputMaybe<Array<InputMaybe<PriceInput>>>;
};

export type UpdateProductInput = {
  additionalImages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  external_ref?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateProductsInput = {
  products?: InputMaybe<Array<InputMaybe<UpdateProductInput>>>;
};

export type UpdateSkuInput = {
  access?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  EAN?: InputMaybe<Scalars['String']>;
  harness?: InputMaybe<Scalars['String']>;
  HS?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  originCountry?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateSkusInput = {
  skus?: InputMaybe<Array<InputMaybe<UpdateSkuInput>>>;
};

export type UpdateSkusInventoryInput = {
  skus?: InputMaybe<Array<InputMaybe<SkusInput>>>;
};

export type UpdateSkusProductListsInput = {
  skus?: InputMaybe<Array<InputMaybe<ProductListsInput>>>;
};

export type User = {
  __typename?: 'User';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  createdOrders?: Maybe<Array<Maybe<OrderCreatedBy>>>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVismaUser?: Maybe<Scalars['Boolean']>;
  resetToken?: Maybe<Scalars['String']>;
  resetTokenExpiry?: Maybe<Scalars['String']>;
  status?: Maybe<EntityStatus>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<TermsAndConditions>;
  termsAndConditionsId?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
};

export type UserCourse = {
  __typename?: 'UserCourse';
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars['String']>;
  enrolled?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserCustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EntityStatus>;
};

export type UserInput = {
  company?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export enum UserOrAdmin {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER'
}

export enum UserType {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type VatCategory = {
  __typename?: 'VatCategory';
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  vatRates?: Maybe<Array<Maybe<VatRate>>>;
  vatZones?: Maybe<Array<Maybe<VatZone>>>;
};

export type VatInformation = {
  __typename?: 'VatInformation';
  vatCategories?: Maybe<Array<Maybe<VatCategory>>>;
  vatRates?: Maybe<Array<Maybe<VatRate>>>;
  vatZones?: Maybe<Array<Maybe<VatZone>>>;
};

export type VatRate = {
  __typename?: 'VatRate';
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  revisionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  vatCategory?: Maybe<VatCategory>;
  vatCategoryId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type VatZone = {
  __typename?: 'VatZone';
  companies?: Maybe<Array<Maybe<Company>>>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  vatCategories?: Maybe<Array<Maybe<VatCategory>>>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  sortNumber?: Maybe<Scalars['Int']>;
  stock?: Maybe<Array<Maybe<Stock>>>;
  stores?: Maybe<Array<Maybe<Store>>>;
};
