import { gql } from "@apollo/client";

export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      message
    }
  }
`;

export const SIGNIN_VIA_TEACHABLE_MUTATION = gql`
  mutation SIGNIN_VIA_TEACHABLE_MUTATION($teachableToken: String) {
    loginViaTeachable(teachableToken: $teachableToken) {
      user {
        id 
        email
      }
      token
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation REQUEST_PASSWORD_RESET_MUTATION($email: String) {
    requestReset(email: $email) {
      message
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION(
    $password: String
    $confirmPassword: String
    $resetToken: String
  ) {
    resetPassword(
      password: $password
      confirmPassword: $confirmPassword
      resetToken: $resetToken
    ) {
      message
    }
  }
`;

export const RESEND_RESET_EMAIL = gql`
  mutation RESEND_RESET_EMAIL($email: String) {
    resendEmail(email: $email) {
      message
    }
  }
`;

export const INVITE_USER_MUTATION = gql`
  mutation INVITE_USER_MUTATION($email: String) {
    inviteUser(email: $email) {
      message
    }
  }
`;

export const ACKNOWLEDGE_NOTIFICATION = gql`
  mutation ACKNOWLEDGE_NOTIFICATION($notificationId: String) {
    acknowledgeNotification(notificationId: $notificationId) {
      id
      message
      type
      acknowledged {
        id
      }
    }
  }
`;

export const SEND_EMAIL_TO_MANAGERS = gql`
  mutation SEND_EMAIL_TO_MANAGERS($storeId: String!) {
    sendEmailToManagers(storeId: $storeId) {
      message
    }
  }
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation ADD_TO_CART_MUTATION($cartInput: cartInput, $currency: String, $basePrice: String) {
    addToCart(cartInput: $cartInput, currency: $currency, basePrice: $basePrice) {
      id
      totalPrice
      totalVAT
      vatRate
      shippingCost
      discountedBy
      items {
        quantity
        id
        price
        discountedBy
        item {
          id
          sku
          name
          inventory
          size
          color
          version
          product {
            id
            name
            applyDiscount
          }
          prices(currency: $currency, basePrice: $basePrice) {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
`;


export const ADD_TO_CART_PORTAL_MULTIPLE_MUTATION = gql`
  mutation ADD_TO_CART_PORTAL_MULTIPLE_MUTATION($cartInputPortalMultiple: cartInputPortalMultiple, $currency: String, $basePrice: String) {
    addToCartPortalMultiple(cartInputPortalMultiple: $cartInputPortalMultiple, currency: $currency, basePrice: $basePrice) {
      id
      totalPrice
      totalVAT
      vatRate
      shippingCost
      discountedBy
      items {
        quantity
        id
        price
        discountedBy
        item {
          id
          sku
          name
          inventory
          size
          color
          version
          product {
            id
            name
            applyDiscount
          }
          prices(currency: $currency, basePrice: $basePrice) {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
  `;


export const REMOVE_FROM_CART_MUTATION = gql`
  mutation REMOVE_FROM_CART_MUTATION($orderItemId: String!, $currency: String, $basePrice: String) {
    removeFromCart(orderItemId: $orderItemId) {
      id
      shippingCost
      totalPrice
      totalVAT
      vatRate
      discountedBy

      items {
        quantity
        id
        price
        discountedBy
        item {
          id
          name
          sku
          inventory
          size
          color
          version
          product {
            id
            name
            applyDiscount
          }
          prices (currency: $currency, basePrice: $basePrice) {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT_MUTATION = gql`
  mutation CHECKOUT_MUTATION($orderId: String) {
    checkout(orderId: $orderId) {
      id
      edit
    }
  }
`;

export const EDIT_ORDER_MUTATION = gql`
  mutation EDIT_ORDER_MUTATION($orderId: String) {
    editOrder(orderId: $orderId) {
      id
      edit
      items {
        quantity
        item {
          id
          name
          sku
          version
          inventory
          product {
            id
            name
          }
          prices {
            baseAmount
            basePrice {
              name
            }
            currency {
              currency_code
            }
          }
        }
      }
    }
  }
`;

export const PLACE_ORDER_MUTATION = gql`
  mutation PLACE_ORDER_MUTATION(
    $orderId: String
    $comment: String
    $storeOrderId: String
    $createStripePaymentSku: Boolean
  ) {
    placeOrder(
      orderId: $orderId
      comment: $comment
      storeOrderId: $storeOrderId
      createStripePaymentSku: $createStripePaymentSku
    ) {
      removedSkus
      counter
      order {
        id
        totalPrice
        totalVAT
        vatRate
        discountedBy
        edit
        comment
        status
        paid
        discountedBy
        shippingCost
        items {
          quantity
          id
          price
          item {
            id
            sku
            name
            version
            product {
              id
              name
            }
            prices {
              baseAmount
              basePrice {
                name
              }
              currency {
                currency_code
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ORDER_MUTATION = gql`
  mutation PLACE_ORDER_MUTATION($orderId: String) {
    deleteOrder(orderId: $orderId) {
      message
    }
  }
`;

export const GET_ALERTS_FOR_SKU = gql`
  mutation GET_ALERTS_FOR_SKU($skuId: String, $active: Boolean) {
    getAlertsForSku(skuId: $skuId, active: $active) {
      id
    }
  }
`;
export const INFORM_ADMINS_ABOUT_ICORRECT_DATA = gql`
  mutation INFORM_ADMINS_ABOUT_ICORRECT_DATA(
    $incorrectCompanyDataInput: IncorrectCompanyDataInput
  ) {
    informAdminsAboutIncorrectData(
      incorrectCompanyDataInput: $incorrectCompanyDataInput
    ) {
      message
    }
  }
`;

export const CUSTOMER_REGISTRATION = gql`
  mutation CUSTOMER_REGISTRATION($input: RegisterCustomerInput) {
    customerRegistration(input: $input) {
      id
    }
  }
`;

export const CONFIRM_INFORMATION = gql`
  mutation CONFIRM_INFORMATION {
    confirmInformation {
      message
    }
  }
`;

export const ACCEPT_TERMS_AND_CONDITIONS_MUTATION = gql`
  mutation AcceptTermsAndConditions($userId: String, $storeId: String) {
    acceptTermsAndConditions(userId: $userId, storeId: $storeId) {
      id
      termsAndConditionsAccepted
    }
  }
`;

export const ALERT_PRICE_MISSING = gql`
  mutation ALERT_PRICE_MISSING($sku: String) {
    alertAdminsPriceMissing(sku: $sku) {
      message
    }
  }
`;

export const ENROLL_TO_TEACHABLE_COURSE = gql`
  mutation ENROLL_TO_TEACHABLE_COURSE($courseId: String, $userId: String) {
    enrollToTeachableCourse(courseId: $courseId, userId: $userId) {
      message
    }
  }
`;

export const SALES_NOTIFICATION = gql`
  mutation SALES_NOTIFICATION($orderId: String) {
    salesNotification(orderId: $orderId) {
      message
      
    }
  }
`;